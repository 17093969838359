<div class="limiter d-none">
  <div
    class="container-login100"
    style="background-image: url('../../../assets/images/dhanush/bg-image.png')"
  >
    <div class="wrap-login100" style="padding: 22px 55px 24px 55px">
      <div class="text-center">
        <img
          id="homepageimage_path"
          src="../../../../assets/img/ouk-logo-new.png"
          [routerLink]="['']"
          style="cursor: pointer; width: 60%; height: auto"
          class="img-fluid"
        />
      </div>
      <br />
      <form class="login100-form validate-form" [formGroup]="signUpForm">
        <span class="login100-form-title p-b-24">
          <span style="font-size: 22px">Sign Up</span>
        </span>
        <br />

        <div class="wrap-input100 validate-input mt-2">
          <input
            class="input100"
            formControlName="FirstName"
            type="text"
            name="FirstName"
            maxlength="50"
            (input)="nameValidate($event)"
          />
          <span class="focus-input100" data-placeholder="First Name"></span>
        </div>
        <!-- <div *ngIf="submitted && f && f?.FirstName && f?.FirstName?.errors" class="invalid-feedback">
                    <div *ngIf="f?.name?.errors?.required">First name is required</div>
                </div> -->

        <div class="wrap-input100 validate-input mt-2">
          <input
            class="input100"
            formControlName="LastName"
            type="text"
            name="LastName"
            maxlength="50"
            (input)="nameValidate($event)"
          />
          <span class="focus-input100" data-placeholder="Last Name"></span>
        </div>
        <!-- <div *ngIf="submitted&& f && f.LastName && f.LastName.errors" class="invalid-feedback">
                    <div *ngIf="f.name.errors.required">Last name is required</div>
                </div> -->
        <!-- [ngClass]="{ 'is-invalid': submitted && f.Mobileno?.errors }" -->
        <!-- <div class="wrap-input100 validate-input mt-4">
                    <input class="input100" formControlName="Mobileno" type="text" name="Mobileno">
                    <span class="focus-input100" data-placeholder="Mobile"></span>
                </div> -->

        <div
          class="wrap-input100 validate-input mt-4"
          data-validate="Valid email is: a@b.c"
        >
          <input
            class="input100"
            [(ngModel)]="EMAIL_ADDRESS"
            (change)="validateEmail($event)"
            formControlName="UserName"
            type="text"
            name="UserName"
            maxlength="100"
          />
          <span class="focus-input100" data-placeholder="Email"></span>
        </div>
        <!-- <div *ngIf="submitted && f.UserName.errors" class="invalid-feedback">
                    <div *ngIf="f.UserName.errors.required">Email is required</div>
                    <div *ngIf="f.UserName.errors.pattern">Please enter valid email</div>
                </div> -->
        <!-- <div *ngIf="submitted && f && f.UserName && f.UserName.errors" class="invalid-feedback">
                    <div *ngIf="f.UserName.errors.required">Email is required</div>
                    <div *ngIf="f.UserName.errors.pattern">Please enter a valid email</div>
                </div> -->
      </form>
      <div class="text-center wrap-input100 validate-input mt-4">
        <!-- <div id="captcha">
                </div> -->
        <input
          type="text"
          class="input100"
          placeholder="Enter Captcha Here"
          [(ngModel)]="userResponse"
        />
        <span class="focus-input100" data-placeholder=""></span>
        <!-- {{userResponse}} -->
        <!-- <button type="submit" >Submit</button>  -->
      </div>
      <div class="container-login100-form-btn">
        <div class="wrap-login100-form-btn">
          <div class="login100-form-bgbtn"></div>

          <button
            type="submit"
            class="login100-form-btn"
            (click)="validateCaptcha()"
          >
            Submit
          </button>
        </div>
      </div>

      <div class="container-login100-form-btn">
        <span
          >Already have an account?
          <a [routerLink]="['/login']"><u>Login</u></a></span
        >
      </div>
    </div>
  </div>
</div>
<app-student-address
  *ngIf="isLoginDetailsSubmitted"
  (addressEvent)="getAddressDetails($event)"
></app-student-address>

<!-- ==================================================================================================
 -->

<div class="bg-white">
  <div class="container-fluid">
    <div class="row login-card">
      <div
        class="col-lg-6 d-flex align-items-center justify-content-center"
        style="background-color: #fff"
      >
        <div class="row">
          <div class="col-md-12 m-auto">
            <div class="card border-0" style="cursor: default">
              <div class="card-body px-md-4 py-3">
                <div class="text-center">
                  <a href="javascript:" style="cursor: default">
                    <img
                      src="../../../assets/img/logo1-new.png"
                      class="img-fluid"
                      *ngIf="!isUrlBasedLogo"
                      style="max-height: 90px; width: auto"
                      alt=""
                    />
                    <img
                      src="../../../assets/img/logo2-new.png"
                      class="img-fluid"
                      *ngIf="isUrlBasedLogo"
                      style="max-height: 90px; width: auto"
                      alt=""
                    />
                  </a>
                </div>
                <h6 class="text-center mt-4" *ngIf="!isUrlBasedLogo">
                  Welcome to Open University of Kenya
                </h6>
                <!-- <h6 class="text-center mt-4">Welcome to Dhanush</h6> -->

                <h5 class="text-center my-4">Signup</h5>
                <div [formGroup]="signUpForm">
                  <div class="row">
                    <div class="col-md-12 m-auto">
                      <div class="form-floating mb-3">
                        <input
                          type="text"
                          class="form-control"
                          id="floatingInput"
                          placeholder=""
                          formControlName="FirstName"
                          maxlength="50"
                          (input)="nameValidate($event)"
                        />
                        <label for="floatingInput">First Name</label>
                      </div>
                      <div class="form-floating mb-3">
                        <input
                          type="text"
                          class="form-control"
                          id="floatingInput"
                          placeholder=""
                          formControlName="LastName"
                          maxlength="50"
                          (input)="nameValidate($event)"
                        />
                        <label for="floatingInput">Last Name</label>
                      </div>

                      <div class="form-floating mb-3">
                        <app-search-dropdown
                          [SearchData]="countrySearchData"
                          (notify)="changeSearchDropdown($event)"
                        ></app-search-dropdown>
                        <!-- <input type="text" name="example" class="form-control  "
                                                    [(ngModel)]="selectedCountry" [ngModelOptions]="{standalone: true}"
                                                    list="exampleList" (change)="getId($event.target.value)"
                                                    autocomplete="off">
                                                <datalist id="exampleList">
                                                    <option *ngFor='let item of country' [value]='item.COUNTRY_NAME'>
                                                        {{item.COUNTRY_NAME}}
                                                    </option>
                                                </datalist>
                                                <label for="countryDrpdown">Select Country</label> -->
                      </div>

                      <div class="form-floating input-group mb-3">
                        <select
                          name=""
                          id=""
                          class="form-select"
                          [(ngModel)]="countryCode"
                          [ngModelOptions]="{ standalone: true }"
                        >
                          <option
                            *ngFor="let item of country"
                            [value]="item.COUNTRY_ID"
                          >
                            {{ item.COUNTRY_CONNECTING_CODE }}
                          </option>
                        </select>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Mobile Number"
                          formControlName="Mobileno"
                          (blur)="mobileValidation($event)"
                          (keypress)="onKeyPress($event)"
                          (paste)="onPaste($event)"
                          maxlength="12"
                          id="floatingInput"
                        />
                        <!-- <label for="floatingInput">Mobile Number</label> -->
                      </div>

                      <!-- <div class="form-floating mb-3">
                                                <select name="" id="" class="form-select"></select>
                                                <input type="text" class="form-control" id="floatingInput"
                                                    placeholder="" formControlName="MobileNo" maxlength="12">
                                                <label for="floatingInput">Mobile Number</label>
                                            </div> -->
                      <div
                        class="form-floating"
                        data-validate="Valid email is: a@b.c"
                      >
                        <input
                          type="email"
                          class="form-control"
                          id="floatingPassword"
                          placeholder=""
                          [(ngModel)]="EMAIL_ADDRESS"
                          (change)="validateEmail($event)"
                          formControlName="UserName"
                          maxlength="100"
                        />
                        <label for="floatingPassword">Email</label>
                      </div>

                      <div class="row mt-3">
                        <div class="col-6">
                          <div class="text-center mt-3">
                            <!-- <div id="captcha">
                                                        </div> -->
                            <div class="captcha-container">
                              <div class="captcha-box">
                                <p>{{ captchaQuestion }} =</p>
                              </div>
                            </div>
                            &nbsp;
                            <a href="javascript:" (click)="speakCaptcha()"
                              ><i class="fas fa-volume-up"></i></a
                            >&nbsp;&nbsp;
                            <a href="javascript:" (click)="refreshCaptcha()"
                              ><i class="fas fa-sync"></i
                            ></a>
                          </div>
                        </div>

                        <div class="col-6 pt-4">
                          <div class="form-floating">
                            <input
                              class="form-control border-top-0 border-start-0 border-end-0"
                              placeholder=""
                              [(ngModel)]="userAnswer"
                              [ngModelOptions]="{ standalone: true }"
                              placeholder="Enter Captcha"
                            />

                            <!-- <input
                                                            class="form-control   border-top-0 border-start-0 border-end-0"
                                                            placeholder="" [(ngModel)]="userResponse"
                                                            [ngModelOptions]="{standalone:true}"
                                                            placeholder="Enter Captcha"> -->
                          </div>
                        </div>
                        <div class="col-12">
                          <a
                            type="signup"
                            class="btn btn-primary lgn-btn py-2 d-block fw-500 mt-4"
                            (click)="validateCaptcha()"
                            >Signup</a
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="clearfix"></div>
      </div>
      <div
        class="col-lg-6 d-flex align-items-center justify-content-center text-white"
        style="background-color: #037b90"
      >
        <div class="row">
          <div class="col-md-12">
            <div
              class="card border-0 bg-transparent border-0 justify-content-center"
            >
              <div class="card-body px-md-5 py-5">
                <h5 class="text-center">Welcome!</h5>
                <h5 class="text-center mt-4">Already have an account?</h5>
                <br />
                <a
                  [routerLink]="['/login']"
                  class="btn btm-md btn-primary lgn-btn d-block fw-500 mt-4 py-2"
                  >Login</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
