import { SpeechToTextComponent } from "./speech-to-text/speech-to-text.component";
import { Component, NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { MaincontentComponent } from "./maincontent/maincontent.component";
// import { CoursesResolver } from './resolvers/coursesResolver.resolver';
import { ERPComponent } from "./erp/erp.component";
import { PrivacyPolicyComponent } from "./components/organization-details/privacy-policy/privacy-policy.component";
import { TermsConditionsComponent } from "./components/organization-details/terms-conditions/terms-conditions.component";
import { RefundPolicyComponent } from "./components/organization-details/refund-policy/refund-policy.component";
import { ContactUs1Component } from "./components/organization-details/contact-us/contact-us1.component";
import { ViewCourseDetailsComponent } from "./components/view-course-details/view-course-details.component";
import { EnquiryComponent } from "./pages/enquiry/enquiry.component";
import { HomeComponent } from "./pages/home/home.component";
import { LoginComponent } from "./pages/login/login.component";
import { TutorHomeComponent } from "./pages/tutor-home/tutor-home.component";
import { StudentHomeComponent } from "./pages/student-home/student-home.component";
import { AllCategoryCoursesComponent } from "./pages/all-category-courses/all-category-courses.component";
import { StudentSignupComponent } from "./pages/student-signup/student-signup.component";
import { HeaderNewComponent } from "./components/Shared/header-new/header-new.component";
import { FooterNewComponent } from "./components/Shared/footer/footer-new.component";
import { AboutUsComponent } from "./pages/about-us/about-us.component";
// import { AllCategoriesComponent } from './pages/other-components/all-categories/all-categories.component';
import { AllCoursesComponent } from "./pages/all-courses/all-courses.component";
import { TestMonialsComponent } from "./components/test-monials/test-monials.component";
import { InvoiceComponent } from "./pages/other-components/invoice/invoice.component";
import { TextToSpeechComponent } from "./text-to-speech/text-to-speech.component";
import { ApproveUserComponent } from "./pages/approve-user/approve-user.component";
// import { ApplicationFormComponent } from './pages/other-components/application-form/application-form.component';
import { NewViewCourseDetailsComponent } from "./components/new-view-course-details/new-view-course-details.component";
import { NotFoundComponent } from "./pages/other-components/not-found/not-found.component";
import { ForgotPasswordComponent } from "./pages/forgot-password/forgot-password.component";
import { StaffIdcardHistorylistComponent } from "./pages/Administration/Configure/staff-idcard-historylist/staff-idcard-historylist.component";
import { StuHomeComponent } from "./pages/stu-home/stu-home.component";
import { UnauthorizedComponent } from "./pages/other-components/unauthorized/unauthorized.component";
import { PaymentSuccessComponent } from "./pages/other-components/payment-success/payment-success.component";
import { PaymentFailedComponent } from "./pages/other-components/payment-failed/payment-failed.component";
import { StudentDashboardComponent } from "./student-dashboard/student-dashboard.component";
import { FaqListComponent } from "./pages/faq-list/faq-list.component";
import { ChangePasswordComponent } from "./pages/other-components/change-password/change-password.component";
import { ProgramsComponent } from "./pages/Course-setup/programs/programs.component";
import { SidebarComponent } from "./sidebar/sidebar.component";
import { StudentAvailableProgramsComponent } from "./pages/student-available-programs/student-available-programs.component";
import { MyApplicationComponent } from "./pages/other-components/my-application/my-application.component";
import { AdminDashboardComponent } from "./admin-dashboard/admin-dashboard.component";
import { VendorSignupComponent } from "./pages/vendor-signup/vendor-signup.component";
import { VendorLoginComponent } from "./pages/vendor-login/vendor-login.component";
import { VendorRegistrationComponent } from "./pages/other-components/vendor-registration/vendor-registration.component";
import { AdminDeanHodDashboardComponent } from "./admin-dean-hod-dashboard/admin-dean-hod-dashboard.component";
import { NewStudentDashboardComponent } from "./new-student-dashboard/new-student-dashboard.component";
import { ShortCoursesComponent } from "./pages/short-courses/short-courses.component";

const routes: Routes = [
  {
    path: "enquiry",
    component: EnquiryComponent,
  },
  { path: "vendorSignup", component: VendorSignupComponent },
  {
    path: "vendorlogin",
    component: VendorLoginComponent,
  },
  // {
  //   path: "privacyPolicy",
  //   component: PrivacyPolicyComponent
  // },
  {
    path: "HOME",
    component: HomeComponent,

    children: [
      {
        path: "dashboard",
        component: MaincontentComponent,
      },
      {
        path: "adminDashboard",
        component: AdminDeanHodDashboardComponent,
      },
      {
        path: "studentDashboard",
        component: NewStudentDashboardComponent,
      },
      {
        path: "",
        redirectTo: "adminDashboard",
        pathMatch: "full",
      },
      { path: "studentDash", component: StudentDashboardComponent },
      { path: "SpeechToText", component: SpeechToTextComponent },
      { path: "TextToSpeech", component: TextToSpeechComponent },
      { path: "stuHome", component: StuHomeComponent },
      // { path: 'programs', component: ProgramsComponent },
      { path: "programs", component: StudentAvailableProgramsComponent },
      { path: "shortCourses", component: ShortCoursesComponent },
      { path: "myApplication", component: MyApplicationComponent },

      { path: "sidebar", component: SidebarComponent },
      {
        path: "staffIdCardHistoryList/:id",
        component: StaffIdcardHistorylistComponent,
      },
      {
        path: "canteenModule",
        loadChildren: () =>
          import(
            "./pages/Administration/canteen-Management/canteen-management.module"
          ).then((m) => m.CanteenManagementModule),
      },
      {
        path: "configure",
        loadChildren: () =>
          import("./pages/Administration/Configure/configure.module").then(
            (m) => m.ConfigureModule
          ),
      },
      {
        path: "front-office",
        loadChildren: () =>
          import(
            "./pages/Administration/Front-Office/front-office.module"
          ).then((m) => m.FrontOfficeModule),
      },
      {
        path: "hostel",
        loadChildren: () =>
          import("./pages/Administration/Hostel/hostel.module").then(
            (m) => m.HostelModule
          ),
      },
      {
        path: "inventory",
        loadChildren: () =>
          import("./pages/Administration/Inventory/inventory.module").then(
            (m) => m.InventoryModule
          ),
      },
      {
        path: "leaveManagement",
        loadChildren: () =>
          import(
            "./pages/Administration/Leave-Management/leave-management.module"
          ).then((m) => m.LeaveManagementModule),
      },
      {
        path: "libraryManagement",
        loadChildren: () =>
          import(
            "./pages/Administration/Library-management/library-management.module"
          ).then((m) => m.LibraryManagementModule),
      },
      {
        path: "timetable",
        loadChildren: () =>
          import("./pages/Administration/timetable/timetable.module").then(
            (m) => m.TimetableModule
          ),
      },
      {
        path: "transport",
        loadChildren: () =>
          import("./pages/Administration/Transport/transport.module").then(
            (m) => m.TransportModule
          ),
      },
      {
        path: "communication",
        loadChildren: () =>
          import("./pages/Communication/communication.module").then(
            (m) => m.CommunicationModule
          ),
      },
      {
        path: "courseSetup",
        loadChildren: () =>
          import("./pages/Course-setup/course-setup.module").then(
            (m) => m.CourseSetupModule
          ),
      },
      {
        path: "events",
        loadChildren: () =>
          import("./pages/Events/events.module").then((m) => m.EventsModule),
      },
      {
        path: "Assessment",
        loadChildren: () =>
          import("./pages/Examination/Assessment/assessment.module").then(
            (m) => m.AssessmentModule
          ),
      },
      {
        path: "Assignment",
        loadChildren: () =>
          import("./pages/Examination/Assignment/assignment.module").then(
            (m) => m.AssignmentModule
          ),
      },
      {
        path: "feeManagement",
        loadChildren: () =>
          import("./pages/fee-management/fee-management.module").then(
            (m) => m.FeeManagementModule
          ),
      },
      {
        path: "globalInputs",
        loadChildren: () =>
          import("./pages/global-inputs/global-inputs.module").then(
            (m) => m.GlobalInputsModule
          ),
      },
      {
        path: "misReports",
        loadChildren: () =>
          import("./pages/mis-reports/mis-reports.module").then(
            (m) => m.MisReportsModule
          ),
      },
      {
        path: "emsReports",
        loadChildren: () =>
          import("./pages/mis-reports/ems-reports/ems-reports.module").then(
            (m) => m.EmsReportsModule
          ),
      },
      {
        path: "taskManagement",
        loadChildren: () =>
          import("./pages/task-management/task-management.module").then(
            (m) => m.TaskManagementModule
          ),
      },
      {
        path: "registration",
        loadChildren: () =>
          import("./pages/Registration/registration.module").then(
            (m) => m.RegistrationModule
          ),
      },
      {
        path: "generalStore",
        loadChildren: () =>
          import("./pages/general-store/general-store.module").then(
            (m) => m.GeneralStoreModule
          ),
      },
      {
        path: "components",
        loadChildren: () =>
          import("./pages/other-components/other-components.module").then(
            (m) => m.OtherComponentsModule
          ),
      },
      {
        path: "cautionDeposit",
        loadChildren: () =>
          import(
            "./pages/Administration/caution-deposit/caution-deposit.module"
          ).then((m) => m.CautionDepositModule),
      },
      {
        path: "elections",
        loadChildren: () =>
          import("./pages/elections/elections.module").then(
            (m) => m.ElectionsModule
          ),
      },
      {
        path: "financeManagement",
        loadChildren: () =>
          import("./pages/finance-management/finance-management.module").then(
            (m) => m.FinanceManagementModule
          ),
      },
      {
        path: "graduation",
        loadChildren: () =>
          import("./pages/graduation/graduation.module").then(
            (m) => m.GraduationModule
          ),
      },
      {
        path: "eventReport",
        loadChildren: () =>
          import("./pages/event-report/event-report.module").then(
            (m) => m.EventReportModule
          ),
      },
      {
        path: "eWallet",
        loadChildren: () =>
          import("./pages/e-wallet/e-wallet.module").then(
            (m) => m.EWalletModule
          ),
      },

      {
        path: "student-home",
        component: StudentHomeComponent,
        // resolve: { courseData: CoursesResolver }
      },
      {
        path: "invoice",
        component: InvoiceComponent,
      },
      { path: "approveUser/:id", component: ApproveUserComponent },
      // approveUser/:id
    ],
  },
  {
    path: "",
    redirectTo: "login",
    pathMatch: "full",
  },
  {
    path: "login",
    component: LoginComponent,
    // resolve: { courseData: CoursesResolver }
  },

  { path: "UnauthorizedComponent", component: UnauthorizedComponent },
  { path: "paymentSuccess", component: PaymentSuccessComponent },
  { path: "paymentFailed", component: PaymentFailedComponent },
  {
    path: "forgotPassword",
    component: ForgotPasswordComponent,
  },
  {
    path: "student-home",
    component: StudentHomeComponent,
    // resolve: { courseData: CoursesResolver }
  },
  {
    path: "login",
    component: LoginComponent,
  },
  { path: "notFound", component: NotFoundComponent },
  { path: "changePassword", component: ChangePasswordComponent },
  { path: "vendorRegistration", component: VendorRegistrationComponent },

  {
    path: "Home/dashboard",
    redirectTo: "HOME",
  },
  {
    path: "all-category-courses",
    component: AllCategoryCoursesComponent,
  },
  { path: "student-signup", component: StudentSignupComponent },
  { path: "faq", component: FaqListComponent },
  { path: "view-course-details", component: ViewCourseDetailsComponent },
  { path: "new-view-course-details", component: NewViewCourseDetailsComponent },

  {
    path: "eRP",
    component: ERPComponent,
    children: [
      { path: "about-us", component: AboutUsComponent },
      { path: "contact-us", component: ContactUs1Component },
      { path: "refund-policy", component: RefundPolicyComponent },
      { path: "terms-conditions", component: TermsConditionsComponent },
      { path: "privacy-policy", component: PrivacyPolicyComponent },
      { path: "view-course-details", component: ViewCourseDetailsComponent },
      {
        path: "new-view-course-details",
        component: NewViewCourseDetailsComponent,
      },
      // { path: 'view-profile', component: ViewProfileComponent },
    ],
  },
  {
    path: "project-management",
    loadChildren: () =>
      import(
        "./pages/finance-management/project-management/project-management.module"
      ).then((m) => m.ProjectManagementModule),
  },

  {
    path: "**",
    redirectTo: "tutorHome",
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}

// resolve:{menu:Resolver},
