import {
  Component,
  Input,
  OnInit,
  Renderer2,
  ViewChild,
  ChangeDetectorRef,
} from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { CommonService } from "../services/common.service";
import { DomSanitizer } from "@angular/platform-browser";
import { environment } from "../../environments/environment";
import { SidebarStateService } from "../services/sidebar-state.service";
import { ToastrService } from "ngx-toastr";
import { MenuService } from "../services/menu.service";
import { filter } from "rxjs/operators";
import { MultiSelectComponent } from "ng-multiselect-dropdown";
const url = environment.fileUrl;
// declare var onloadfn: any;
// declare var loadCr: any;
declare var $: any;
@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.css"],
})
export class SidebarComponent implements OnInit {
  @ViewChild("dropdown", { static: false }) dropdown: MultiSelectComponent;
  roleId: any = localStorage.getItem("RoleId") || 3;
  tenantCode: any = localStorage.getItem("TenantCode");
  roleName: any = localStorage.getItem("RoleName");
  applicantId = localStorage.getItem("IsApplicant");
  isApplicant: any;
  menus: Array<any> = [];
  clientLogo: any;
  isLogo: boolean = false;
  url: any;
  selectedMenuItem: any = "Dashboard"; // Initialize with 'Dashboard' or the default active item
  lastSelectedMenuItem: any = null; // Initialize as null
  isShow: any = localStorage.getItem("UserBaseSidebarMenu");
  isSubmit: any = localStorage.getItem("isStudent");
  isExpanded: boolean;
  currentMenu: any;
  allChilds: any[] = [];
  dropdownSettings: any = {};
  selectedMenu: any = [];

  base64Image = `data:image/png;base64,${localStorage.tnt_logo}`;
  @Input() sMaduleId: any = 1;
  desiredUrl: any;

  constructor(
    private renderer: Renderer2,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
    private router: Router,
    private service: CommonService,
    private sanitizer: DomSanitizer,
    private sidebarStateService: SidebarStateService,
    private toastr: ToastrService,
    private menuService: MenuService
  ) {
    this.loadScript();
    // this.getMenu();

    this.sidebarStateService.isExpanded$.subscribe((isExpanded) => {
      this.isExpanded = isExpanded;
    });

    this.roleId = atob(this.roleId);
    this.isApplicant =
      (this.roleId == "3" || this.roleId == 3) && this.applicantId === "1";
    this.isShow =
      this.roleId == "3" || this.roleId == 3
        ? localStorage.getItem("UserBaseSidebarMenu")
        : "true";

    if (localStorage.getItem("Logo") == "") {
      this.isLogo = true;
    } else {
      this.isLogo = false;
    }
  }
  ngOnChanges(changes: any): void {
    if (+changes.sMaduleId.currentValue) {
      this.getMenu();
      let roleID = atob(localStorage.getItem("RoleId")!);
      if ((roleID == "3" || roleID == "1") && this.applicantId != "1") {
        this.getMoodleUrl();
      }

      // this.getMoodleUrl();
    }
    this.dropdownSettings = {
      singleSelection: true,
      idField: "path",
      textField: "Name",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      itemsShowLimit: 1,
      allowSearchFilter: true,
      enableCheckAll: false,
    };
  }
  ngOnInit(): void {
    this.url = window.location.pathname;
    this.clientLogo = this.sanitizer.bypassSecurityTrustResourceUrl(
      `data:image/png;base64, ${localStorage.getItem("Logo")}`
    );
    if (this.sMaduleId !== 1) {
      this.getMenu();
      let roleID = atob(localStorage.getItem("RoleId")!);
      if ((roleID == "3" || roleID == "1") && this.applicantId != "1") {
        this.getMoodleUrl();
      }
      this.sMaduleId = localStorage.getItem("RoleId");
    }
  }
  ngAfterViewInit(): void {
    // The dropdown will be available here
  }
  getClass(name) {
    return "mdi " + name + " menu-icon";
  }
  loadScript() {
    const script = this.renderer.createElement("script");
    script.src = "assets/plugins/metismenu/js/metisMenu.min.js";
    script.async = true;
    script.defer = true;
    this.renderer.appendChild(document.body, script);
  }
  getMoodleUrl() {
    let payload = {
      WSTOKEN: localStorage.getItem("MoodleTkn"),
      username: localStorage.getItem("MoodleUserName"),
      COURSE_ID: 0,
    };
    this.service.activateSpinner();
    this.service
      .postCall("MoodleIntegration/GetMoodleRequestLoginURL", payload)
      .subscribe(
        (res: any) => {
          let splitUrl = res.LoginUrl.split("&wantsurl=");
          this.desiredUrl = splitUrl[0];
          this.service.deactivateSpinner();
          // this.iframeUrl =
          //   this.sanitizer.bypassSecurityTrustResourceUrl(desiredUrl);
        },
        (e) => {
          this.toastr.error(
            e.error.message == null ? "User doesn't exist on LMS" : ""
          );
          this.service.deactivateSpinner();
        }
      );
  }
  navigate(path: string, menu: any, event: Event) {
    if (menu.Name == "LMS" || menu.Name == "SOMAS") {
      // let url = window.location.origin;
      window.open(this.desiredUrl);
      event.preventDefault();
      return;
    }
    if (menu.Name === "Dashboard") {
      // Only set the last selected item if it's not the Dashboard
      if (this.selectedMenuItem !== "Dashboard") {
        this.lastSelectedMenuItem = this.selectedMenuItem;
      }
    } else {
      this.selectedMenuItem = menu.Name;
      localStorage.setItem("menuName", this.selectedMenuItem);
      if (this.selectedMenuItem != "My Student Registration") {
        sessionStorage.setItem("reloaded", "false");
      }
      // Handle navigation logic here

      if (!menu.childs?.length && path) {
        let url = this.url.replaceAll("/HOME/", "");

        let findPath = JSON.stringify(this.menus).includes(path || url);
        if (findPath) {
          this.router.navigate(["HOME/" + path]);
          this.closeLeftMenuforMobile();
          return;
        } else {
          alert(12);
        }
      }
    }
  }
  navigateToOuk() {
    // window.open('https://ouk.ac.ke/')
    this.router.navigate(["/HOME/dashboard"]);
  }
  updateCurrentMenu(url: string): void {
    let currentUrl = url.replace("/HOME/", "");
    this.currentMenu = this.findParentObject(this.menus, currentUrl);
    // console.log(this.currentMenu,'current Menu',currentUrl);
  }
  findParentObject(menus: any, currentPath: any): any {
    const paths = [
      "registration/editUserRegistration",
      "registration/profile",
      "registration/editRegistrationForm",
      "components/regForm",
    ];
    if (currentPath === "dashboard") {
      return "Dashboard";
    } else if (currentPath.includes("components/payment")) {
      return "Fees Accounts";
    } else if (paths.some((path) => currentPath.includes(path))) {
      return "Personal Info";
    } else if (currentPath === "components/prnlDetailsRegForm") {
      return "Personal Details Registration";
    } else if (currentPath === "myApplication") {
      return "My Application";
    }

    for (const menu of menus) {
      // Check if the current menu path matches the current path
      if (menu.path === currentPath) {
        return menu.Name;
      }
      if (menu.childs && menu.childs.length > 0) {
        for (const child of menu.childs) {
          if (child.path === currentPath) {
            return menu.Name;
          } else if (child.isParent && child.childs.length > 0) {
            const result = this.findParentObject(child.childs, currentPath);
            if (result) {
              return menu.Name;
            }
          }
        }
      }
    }
    return null;
  }
  getMenu() {
    let applicantId = localStorage.getItem("IsApplicant");
    let payLoad = {
      Roleid_base64string:
        applicantId == "0" || applicantId == "2"
          ? localStorage.getItem("RoleId")
          : localStorage.getItem("ApplicantRoleId"),
      USER_ID: parseInt(localStorage.getItem("UserId") as any),
      TENANT_CODE: parseInt(localStorage.getItem("TenantCode") as any),
    };

    this.service.postCall("Account/LoadMenusByRoleId", payLoad).subscribe(
      (res: any[]) => {
        this.menus = res;
        let rId = atob(localStorage.getItem("RoleId")!);
        // let libraryMenu = {
        //   Name: "Library",
        //   path: "",
        //   CssClass: "fas fa-book-open",
        //   childs: [
        //     {
        //       Name: "KOHA OUK Library",
        //       path: "http://elibrary.ouk.ac.ke:8080/",
        //       target: "_blank",
        //     },
        //     {
        //       Name: "OUK OPAC Library",
        //       path: "https://elibrary.ouk.ac.ke/",
        //       target: "_blank",
        //     },
        //     {
        //       Name: "My LOFT OUK Library",
        //       path: "https://admin.myloft.xyz",
        //       target: "_blank",
        //     },
        //   ],
        // };

        // if (this.roleId == 1) {
        //   this.menus.splice(this.menus.length - 1, 0, libraryMenu); // Insert at second-last
        // } else if (this.roleId == 3) {
        //   this.menus.splice(this.menus.length - 2, 0, libraryMenu); // Insert at third-last
        // }

        this.menus = [...this.menus];
        this.menuService.setMenus(this.menus);
        let currentURL = window.location.pathname;
        this.allChilds = this.getAllChilds(this.menus);
        if (rId == "2") {
          return this.router.navigate(["/HOME/dashboard"]);
        } else {
          this.updateCurrentMenu(currentURL);
          this.router.events
            .pipe(filter((event) => event instanceof NavigationEnd))
            .subscribe((event: NavigationEnd) => {
              if (event.urlAfterRedirects.includes("HOME")) {
                this.updateCurrentMenu(event.urlAfterRedirects);
              }
            });
        }

        // setTimeout(() => {
        //   if (onloadfn) {
        //     onloadfn();
        //   }
        //   if (loadCr) {
        //     loadCr();
        //   }
        // }, 100)
        return;
        let isSubmitbool = localStorage.getItem("isStudent");
        let roleId: any = atob(this.roleId);
        // Check if 'isSubmitbool' is not null or undefined and convert it to boolean
        if (
          isSubmitbool &&
          isSubmitbool.toLowerCase() === "false" &&
          roleId == 3
        ) {
          // Filter menu items to include only required items for students
          this.menus = this.menus.filter((item) => {
            return (
              item.Name === "My Program" ||
              item.Name === "My Application" ||
              item.Name === "My Student Registration"
            );
          });
        }
        // Handle navigation or further processing of filtered menus here if needed
      },
      (err) => {
        // Handle error
      }
    );
  }

  getId(id: any) {
    return id.replaceAll(/\s/g, "");
  }

  closeLeftMenuforMobile() {
    if (window.matchMedia("(max-width: 767px)").matches) {
      const idClickEvent = document.getElementById("sidebar");
      if (idClickEvent) {
        idClickEvent.classList.value = "wrapper";
      }
    }
  }
  userGuide() {
    const URL = `${url}home/KNA/OUK-StudentRegistration-UserGuide.pdf`;
    window.open(URL);
  }
  navigateToLibraryUrl(url: any) {
    window.open(url);
  }

  flattenChilds(node: any): any[] {
    let result: any[] = [];
    if (node.childs && node.childs.length > 0) {
      node.childs.forEach((child) => {
        result.push(child);
        result = result.concat(this.flattenChilds(child));
      });
    }
    return result;
  }

  getAllChilds(data: any[]): any[] {
    let allChilds: any[] = [];
    data.forEach((item) => {
      allChilds = allChilds.concat(this.flattenChilds(item));
    });
    return allChilds;
  }

  SearchMenuNav() {
    $("#staticBackdrop").modal("show");
  }

  onItemSelect(item: any) {
    if (item.path.split("/").length > 2) {
      this.cdr.detectChanges();
      this.router.navigate(["HOME/" + item.path]);
      this.closeSearch();
    } else {
      this.toastr.warning(
        "Screen not available for selected item please select another item"
      );
    }
  }

  onItemDeSelect(item: any) {}

  closeSearch() {
    $("#staticBackdrop").modal("hide");
    this.selectedMenu = [];
  }
  mydocuments() {
    this.router.navigate(["/HOME/misReports/myDocuments"]);
  }

  isUrlBasedLogo(): boolean {
    const tntLogo: any = localStorage.getItem("tnt_logo");
    return tntLogo?.length > 0 && tntLogo != "undefined";
  }
}
